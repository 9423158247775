<template>
  <div class="card shadow mt-4" style="padding-right:10px; padding-left:10px; padding-top:10px; padding-bottom:10px;">
       <h3 class="purple-title">Room Recommendations</h3>
       <a href="https://app.contentful.com/spaces/e6p3qu1ezvxn/entries?id=lbm7Jw2HfxQeLmpo&contentTypeId=hflRoomRecommendationDefinition&order.fieldId=updatedAt&order.direction=descending&displayedFieldIds=contentType&displayedFieldIds=updatedAt&displayedFieldIds=author&folderId=lHlNKyW2LHWqLyk8&page=1" target="_blank">Details</a>
        <b-table hover responsive
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      small
    >
    </b-table>
    <div class="row">
        <div class="col-md-12">
                <a style="cursor:pointer; font-size:20px;" v-for="number in pageNumbers" :key="number" class="text-primary" @click="setPage(number)">{{number}}&nbsp;&nbsp;</a>
        </div>
    </div>
  </div>
</template>

<script>
import {adminService} from '../../_services/index'
export default {
    mounted(){
        adminService.getAllRoomRecommendationDefinitions()
        .then((response) => {
            this.items = response.data
             var totalItems = this.items.length;
            var pages = Math.ceil(totalItems / this.perPage);
            for(var i = 1; i <= pages; i++){
                this.pageNumbers.push(i);
            }
        })
    },
    data(){
        return{
            showModal: false,
            showModal2: false,
            isLoading: false,
            fullPage: true,
            items:[],

            fields:[
                {
                    key: 'name', label: 'Name', sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'roomTypeName', label: 'Room Type', sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'category', label: "Category", sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'imageLink', label: "Image Link", sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'summary', label: ''
                }
            ],
                totalRows: 1,
                currentPage: 1,
                perPage: 20,
                pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                pageNumbers:[]
        }
    },
    methods:{
        setPage: function(number){
            this.currentPage = number
        }
    }
}
</script>

<style>

</style>