<template>
  <div class="card shadow mt-4" style="padding-right:10px; padding-left:10px; padding-top:10px; padding-bottom:10px;">
       <h3 class="purple-title">Failure Alerts</h3>
       <!-- <router-link to="/admin-dashboard/failure-alerts">Details</router-link> -->
        <b-table hover responsive
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      small
      
    >
    
    </b-table>
    <div class="row">
        <div class="col-md-12">
                <a style="cursor:pointer; font-size:20px;" v-for="number in pageNumbers" :key="number" class="text-primary" @click="setPage(number)">{{number}}&nbsp;&nbsp;</a>
        </div>
    </div>
  </div>
</template>

<script>
import {adminService} from '../../_services/index'
export default {
    mounted(){
        adminService.getAllFailureAlerts()
        .then((response) => {
            this.items = response.data;
            var totalItems = this.items.length;
            var pages = Math.ceil(totalItems / this.perPage);
            for(var i = 1; i <= pages; i++){
                this.pageNumbers.push(i);
            }
        })
    },
    data(){
        return{
            isLoading: false,
            fullPage: true,
            items:[],

            fields:[
                {
                    key: 'alertDate', label: 'Alert Date', sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'message', label: 'Message', sortable: true, sortDirection: 'desc'
                },

            ],
                totalRows: 1,
                currentPage: 1,
                perPage: 20,
                pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                pageNumbers:[]
        }
    },
     methods:{
        setPage: function(number){
            this.currentPage = number;
        }
    }
}
</script>

<style>

</style>