<template>
  <div class="card shadow mt-4" style="padding-right:10px; padding-left:10px; padding-top:10px; padding-bottom:10px;">
       <h3 class="purple-title">Room Properties</h3>
       <router-link to="/admin-dashboard/room-properties">Details</router-link>
        <b-table hover responsive
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      small
    >
    <template #cell(summary)="row">       
        <button style="margin-left:5px;margin-top:5px;" class="btn btn-danger" @click="showModal = true">X</button>
         <Modal v-model="showModal" title="Delete Property">
                <p>Are you sure you would like to delete this property definition?</p>
                <button @click="showModal = false" class="btn purple-btn"> &nbsp;&nbsp; Back</button>
                <button @click="deleteItem(row.item)" style="float:right;" class="btn btn-danger">Delete </button>
        </Modal>
      </template>


    </b-table>
    <div class="row">
        <div class="col-md-12">
                <a style="cursor:pointer; font-size:20px;" v-for="number in pageNumbers" :key="number" class="text-primary" @click="setPage(number)">{{number}}&nbsp;&nbsp;</a>
        </div>
    </div>
  </div>
</template>

<script>
import {adminService} from '../../_services/index'
import VueModal from '@kouts/vue-modal';
import '@kouts/vue-modal/dist/vue-modal.css'
export default {
     components:{
        'Modal':VueModal,
    },
    mounted(){
        adminService.getAllRoomPropertyDefinitions()
        .then((response) => {
            this.items = response.data;
            var totalItems = this.items.length;
            var pages = Math.ceil(totalItems / this.perPage);
            for(var i = 1; i <= pages; i++){
                this.pageNumbers.push(i);
            }
        })
       

    },
    data(){
        return{
            isLoading: false,
            showModal: false,
            fullPage: true,
            items:[],

            fields:[
                {
                    key: 'name', label: 'Name', sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'roomTypeName', label: 'Room Type', sortable: true, sortDirection: 'desc'
                },
                 {
                    key: 'summary', label: ''
                }
            ],
                totalRows: 1,
                currentPage: 1,
                perPage: 20,
                pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                pageNumbers:[]
        }
    },
    methods:{
        setPage: function(number){
            this.currentPage = number;
        },
         deleteItem(row){
            adminService.deleteRoomPropertyDefinition(row)
            .then(() => {
                this.showModal = false;
                this.$toast.success("Successfully Deleted Property!", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
            }, () => {
                this.showModal = false;
                 this.$toast.error("Unable to delete property.", {
                        position: 'bottom-center',
                        timeout: 5000,
                        hideProgressBar: true
            })
            })
        }
    }
}
</script>

<style>

</style>